<template>
    <section class="formular">
        <!--SALARIES-->
        <v-container fluid class="pa-6">
            <v-row :class="{ 'rgg-grey' : $vuetify.theme.dark }" class="grey lighten-4 rounded mb-2 pa-1 pb-3">
                <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                    {{ $t('provisions.dialog.salaries') }}
                </v-col>
                <v-col cols="12" class="pa-0 ma-0 caption gold--text" align="right">
                    <span class="pr-2">{{ $t('provisions.dialog.salaries_count') }}: {{ getSalaries.total_count }}</span>
                </v-col>
                <v-col cols="12" class="pa-0" v-for="item in getSalaries.items" :key="item.id" @click="showDetail(item)">
                    <Card card="SalaryCard" :item="item" :keys="card_headers"  />
                </v-col>
            </v-row>
            <v-pagination v-if="(filter.limit - getSalaries.total_count) < 0"
                          v-model="filter.page"
                          :length="Math.ceil(getSalaries.total_count / filter.limit)"
                          :total-visible="10"
                          color="gold"
                          class="py-3 pb-5"
            />
            <div v-else class="py-6"></div>
        </v-container>

        <!--SALARY DETAIL-->
        <v-dialog v-model="dialog_detail" persistent scrollable fullscreen>
            <v-card flat v-if="dialog_detail">
                <v-toolbar fixed dark dense color="gold" style="z-index: 10; flex: unset;">
                    <v-btn icon dark @click="dialog_detail = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                        <span>{{ $t('provisions.dialog.salary_date') }} {{ detail_date }}</span>
                    </v-toolbar-title>
                </v-toolbar>

                <template>
                    <v-tabs
                        fixed-tabs height="32"
                        color="gold" background-color="#0000000F"
                        active-class="gold lighten-1 white--text" style="flex: unset;"
                    >
                        <v-tab @click="fillSelector('agreements_provisions_list_select')">
                            <v-icon left small>mdi-cash-100</v-icon>{{ $t('provisions.dialog.button_provisions') }}</v-tab>
                        <v-tab @click="fillSelector('agreements_rents_list_select')">
                            <v-icon left small>mdi-finance</v-icon>{{ $t('provisions.dialog.button_rents') }}</v-tab>
                        <v-tab @click="fillSelector('packages_provisions_list_select')">
                            <v-icon left small>mdi-package-variant-closed</v-icon>{{ $t('provisions.dialog.button_packages') }}</v-tab>
                    </v-tabs>
                </template>

                <v-card-text class="pa-0 pb-12">
                    <DataSelect v-if="show" :data_view_name="getDataSelect" :filter_push="filterOverride" />
                </v-card-text>

                <v-card-actions :class="this.$vuetify.theme.dark ? 'black' : 'grey lighten-2'">
                    <v-spacer></v-spacer>
                    <v-btn color="gold" dark small @click="downloadInvoice">
                        <v-icon left>mdi-cloud-download</v-icon>
                        <span>{{ $t('products_packages.dialog.download_invoice') }}</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>

            </v-card>
        </v-dialog>

    </section>
</template>

<script>
import {downloadSalaryInvoice} from "@/api/salaries";
import AuthMixin from "@/services/auth/auth_mixin";
import Card from "@/components/Card";
import DataSelect from "@/components/DataSelect";

export default {
    name: "SalaryDialog",
    components: {
        Card,
        DataSelect
    },
    props: {
        edit: Boolean,
        id: undefined,
        card_item: undefined
    },
    data: () => ({
        dialog_detail: false,
        category: 'provisions',
        filter: {
            page: 1,
            limit: 20,
            toolbar_filter: {}
        },
        salary: null,
        category_data: [],
        card_headers: [
            {value: 'date'},
            {value: 'type'},
            {value: 'amount'},
            {value: 'rent_amount'},
            {value: 'paid'}
        ],
        detail_date: "",
        current_dataselect: 'agreements_provisions_list_select',
        show: true
    }),
    mixins: [AuthMixin],
    created() {
        this.$store.dispatch('getSalaries', {seller_id: this.id, filter_data: this.filter});
        this.$parent.$emit('action', {func: 'setToolbarTitle', data: this.card_item.name});
    },
    computed: {
        getSalaries() {
            return this.$store.getters.getSalaries;
        },
        getCategoryData() {
            return this.category_data;
        },
        getDataSelect() {
            return this.current_dataselect;
        },
        filterOverride() {
            let filter = {};
            filter.salary_id = this.salary;

            return filter;
        }
    },
    watch: {
        'filter.page':function() {
            this.$store.dispatch('getSalaries', {seller_id: this.id, filter_data: this.filter});
        }
    },
    methods: {
        toFunc(e) {
            this[e.func](e.data);
        },
        showDetail(_item) {
            this.dialog_detail = true;
            this.category = 'provisions';
            this.salary = _item.id;
            this.detail_date = _item.date;
        },
        fillSelector(_store) {
            this.show = false;
            this.current_dataselect = _store;
        },
        downloadInvoice() {
            this.$root.$emit('overlay', true);
            downloadSalaryInvoice(this.salary, this.detail_date).then(() => {
                this.$root.$emit('overlay', false);
            }).catch( () => {
                this.$root.$emit('overlay', false);
                this.$root.$emit('notification', {
                    type: 'error',
                    icon: 'info',
                    msg: 'notification.server_error'
                });
            })
        }
    },
    mounted() {
        this.$root.$on('dataselect-done', () => {
            this.show = true;
        });

        this.$root.$on('item-provision', (id, _item, _uid) => {
            this.$root.$emit('open-dialog', id, _uid);
        });
    },
    destroyed() {
        this.$store.dispatch('clearSalaries');
        this.$root.$off('dataselect-done');
        this.$root.$off('item-provision');
    }
}
</script>

<style>
    .v-toolbar__extension {
        background-color: rgba(0,0,0,0.2);
    }
    .tab-selected {
        background-color: rgba(255,255,255,0.5);
    }
</style>